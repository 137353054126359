<template>
  <trac-loading v-if="isLoading"></trac-loading>
  <main v-else>
    <div @click="$router.go(-1)" class="flex cursor-pointer items-center">
      <div class="p-2 border border-opacity-25 rounded-md border-blue-500">
        <svg
          class="w-5 h-5 fill-current text-blue-500"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.2541 4.24106C16.5522 4.53326 16.5793 4.99051 16.3354 5.31272L16.2541 5.40503L9.52658 12L16.2541 18.595C16.5522 18.8872 16.5793 19.3444 16.3354 19.6666L16.2541 19.7589C15.956 20.0511 15.4896 20.0777 15.161 19.8386L15.0668 19.7589L7.7459 12.582C7.44784 12.2898 7.42074 11.8325 7.66461 11.5103L7.7459 11.418L15.0668 4.24106C15.3947 3.91965 15.9262 3.91965 16.2541 4.24106Z"
          />
        </svg>
      </div>
      <div class="ml-2 text-sm text-black font-semibold">Back</div>
    </div>
    <h1 class="text-2xl font-bold mt-10">Pricing band portal</h1>
    <form @submit.prevent="handleBusinessSearch">
      <div class="flex items-center mt-5">
        <input
          type="text"
          class="inline-block  px-2 ring-2 outline-none ring-blue-500 rounded-sm w-72 text-sm py-2"
          name=""
          placeholder="Search Merchant name or email"
          id=""
          v-model="search"
        />
        <button
          class="bg-blue-600 py-3 mx-5 px-6 rounded-md text-xs text-white"
          type="submit"
        >
          Search Records
        </button>
        <button
          class="bg-red-600 py-3 px-6 rounded-md text-xs text-white"
          @click="handleClearSearch"
        >
          Clear
        </button>
      </div>
    </form>
    <div class="mt-5" style="max-height:600px; overflow:auto">
      <ul v-if="businesses.length > 0">
        <li
          v-for="(business, i) in businesses"
          class="bg-blue-100 rounded p-5 my-5"
          :key="i"
        >
          {{ business.business_name }}<br />
          Merchant Email: {{ business.email }}<br />
          <button
            @click="assignMerchant(business)"
            class="bg-white py-3 mt-2 px-6 rounded-md text-xs text-blue-800"
          >
            Assign Band &raquo;
          </button>
        </li>
      </ul>
    </div>
  </main>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  data() {
    return {
      search: null,
      isLoading: false,
      businesses: [],
    };
  },
  methods: {
    assignMerchant(business) {
      // console.log(business);
      this.$router.push({
        name: "PricingBandAssignBusinessPortal",
        params: {
          id: business.business_id,
          merchant: business.business_name,
        },
      });
    },
    async handleBusinessSearch() {
      if (this.search.length < 3) {
        alert("Searched text must be at least three or more characters.");
        return;
      }
      this.isLoading = true;
      let search = this.search;
      let res = await this.$store.dispatch(
        "FETCH_MERCHANT_USING_NAME_OR_EMAIL",
        { search }
      );
      this.isLoading = false;
      if (res.status) {
        this.businesses = res.data.items;
      } else {
        alert(res.error);
      }
    },

    handleClearSearch() {
      this.search = "";
      this.businesses = [];
    },
  },
  async created() {},
};
</script>

<style scoped></style>
